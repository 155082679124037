/**
 * Functionality for the PWZ ('Postwertzeichen') integration.
 *
 * Small script for integrating the IPNP content in the NOL-P widget by calling the provided function.
 */

window.pwz = window.pwz || {};

pwz.druckerei = {
	'01': 'Bundesdruckerei GmbH, Berlin',
	'02': 'Bagel Security-Print GmbH & Co. KG',
	'03': 'Giesecke & Devrient GmbH, Werk Wertpapierdruckerei Leipzig',
	'04': 'Royal Joh. Enschedé',
	'05': 'Deutscher Philatelie Service GmbH',
	'06': 'Bong GmbH',
	'07': 'Otto Theobald GmbH',
	'08': 'SCS Solutions',
	'09': 'Bong GmbH'
};

pwz.produktionsart = {
	'01': 'nassklebendes Postwertzeichen im Zehnerbogen',
	'02': 'nassklebendes Postwertzeichen Rolle',
	'03': 'nassklebendes Sonderpostwertzeichen im Zehnerbogen',
	'04': 'selbstklebendes Sonderpostwertzeichen im Markenset',
	'05': 'selbstklebendes Sonderpostwertzeichen im Maxiset',
	'06': 'selbstklebendes Sonderpostwertzeichen in Markenbox',
	'07': 'nassklebendes Sonderpostwertzeichen mit Zuschlag im Zehnerbogen',
	'08': 'selbstklebendes Sonderpostwertzeichen mit Zuschlag Markenset',
	'09': 'selbstklebendes Sonderpostwertzeichen mit Zuschlag Markenbox',
	'0A': 'selbstklebendes Postwertzeichen im Markenset',
	'0B': 'selbstklebendes Postwertzeichen Markenbox',
	'0C': 'selbstklebendes Postwertzeichen Maxirolle',
	'0D': 'nassklebendes Sonderpostwertzeichen Blockausgabe',
	'0E': 'nassklebendes Sonderpostwertzeichen mit Zuschlag Blockausgabe',
	'0F': 'nassklebendes Postwertzeichen Zusammendruck',
	'10': 'nassklebendes Sonderpostwertzeichen im Markenset',
	'11': 'nassklebendes Sonderpostwertzeichen Zusammendruck 10er Bogen',
	'12': 'nassklebendes Sonderpostwertzeichen Zusammendruck',
	'13': 'nassklebendes Sonderpostwertzeichen Rolle',
	'14': 'selbstklebendes Sonderpostwertzeichen Maxirolle',
	'15': 'Automatenmarke',
	'16': 'Sonderpostwertzeichen blanko Ganzsache eingedruckt',
	'17': 'nassklebendes Postwertzeichen Markenset',
	'18': 'nassklebendes Sonderpostwertzeichen mit Zuschlag Markenset',
	'19': 'nassklebendes Sonderpostwertzeichen mit Zuschlag Zusammendruck',
	'1A': 'Sonderpostwertzeichen Plusbriefsache eingedruckt',
	'1B': 'Postwertzeichen Plusbriefsache eingedruckt',
	'1C': 'Sonderpostwertzeichen Pluskarte eingedruckt',
	'1D': 'Postwertzeichen Pluskarte eingedruckt',
	'32': 'Individual Plusbriefsache eingedruckt',
	'33': 'Individual Pluskarte eingedruckt',
	'34': 'Individual Produkt eingedruckt',
	'35': 'Individual Produkt eingedruckt',
	'36': 'Individual Produkt eingedruckt'
};

/**
 * Function called by the NOLP widget for integrating the IPNP content into the widget.
 *
 * @param pwzDetailContainer Container element for the integration.
 * @param pwzCallback Callback function which will be called after the content has been successfully integrated.
 */
pwz.integratePwzDetail = function(pwzDetailContainer, pwzCallback) {
	'use strict';

	const pwzBasePath = document.querySelector('div[data-pwz-base-path]')?.dataset.pwzBasePath;
	let pwzContentUrl = '/de/toolbar/shared/pwz/';
	if (pwzBasePath !== undefined && pwzBasePath.length > 0) {
		pwzContentUrl = pwzBasePath + pwzContentUrl;
	}
	const containerClass = '.container';
	const pwzDetail = $(pwzDetailContainer);
        if (pwzDetail.length > 0) {
            const erzeugerId = pwzDetail.attr('erzeugerID');
            if (erzeugerId !== undefined) {
                // Create a page fragment by appending the URL with a jQuery-selector. Note: the whitespace after '.html' is important!
                const pageFragmentUrl = pwzContentUrl + erzeugerId + '.data.html ' + containerClass;
                // Load content according to the 'erzeugerID'.
                const content = $('<div></div>').load(pageFragmentUrl, function(responseText, textStatus) {
                    if (textStatus === 'success') {
                        const $content = $(content);
                        // Fill in variable and semi-variable values, or remove <p>-tag if no information is given.
                        const frankierId = pwzDetail.attr('frankierID');
                        if (frankierId !== undefined) {
                            $content.find('#pwzDetail-id').parent().html('<b>ID:</b><br>' + frankierId);
                        } else {
                            $content.find('#pwzDetail-id').parent().remove();
                        }
                        const laufendeNummer = pwzDetail.attr('laufendeNummer');
                        if (laufendeNummer !== undefined) {
                            $content.find('#pwzDetail-ln').parent().html('<b>Laufende Nummer:</b><br>' + laufendeNummer);
                        } else {
                            $content.find('#pwzDetail-ln').parent().remove();
                        }
                        const produktionsart = pwzDetail.attr('produktionsArt');
                        if (produktionsart !== undefined && pwz.produktionsart[produktionsart] !== undefined) {
                            $content.find('#pwzDetail-p').parent().html('<b>Produkt:</b><br>' + pwz.produktionsart[produktionsart]);
                        } else {
                            $content.find('#pwzDetail-p').parent().remove();
                        }
                        const druckerei = pwzDetail.attr('druckerei');
                        if (druckerei !== undefined && pwz.druckerei[druckerei] !== undefined) {
                            $content.find('#pwzDetail-d').parent().html('<b>Druck:</b><br>' + pwz.druckerei[druckerei]);
                        } else {
                            $content.find('#pwzDetail-d').parent().remove();
                        }
                        // Adapt IDs of accordion container to allow multiple integration of the content.
                        const accordionContainer = $content.find('a.jumptarget');
                        if (accordionContainer.length > 0) {
                            accordionContainer.each(function() {
                                if ($(this).is('[data-target]')) {
                                    const newDataTarget = $(this).attr('data-target') + '_' + frankierId;
                                    $(this).attr('data-target', newDataTarget);
                                    if ($(this).next().hasClass('panel-collapse')) {
                                        $(this).next().attr('id', newDataTarget.replace('#', ''));
                                    }
                                }
                            });
                        }
                        // Append content to the container and execute callback function (if provided).
                        pwzDetail.append($content);
                        if (typeof pwzCallback !== 'undefined') {
                            pwzCallback();
                        }
                    }
                });
            }
        }
};
