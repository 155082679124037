'use strict';

function _instanceof(left, right) {
	if (right !== null && typeof Symbol !== 'undefined' && right[Symbol.hasInstance]) {
		return !!right[Symbol.hasInstance](left);
	} else {
		return left instanceof right;
	}
}

/**
 * Component for handling messages between our pages and its parent if integrated via IFrame. Mainly used in the business customer portal
 * ('Geschäftskundenportal, GK-P').
 */
(function ($) {

	$.widget('ipnp.iframe', {
		options: {
			whitelist: [
				'https://externalframe.ref.gkplattform-npi-dmz.test.azure.deutschepost.de',
				'https://internalframe.ref.gkplattform-npi-dmz.test.azure.deutschepost.de',
				'https://externalframe.int.gkplattform-npi-dmz.test.azure.deutschepost.de',
				'https://internalframe.int.gkplattform-npi-dmz.test.azure.deutschepost.de',
				'https://geschaeftskunden.dhl.de',
				'https://geschaeftskunden.deutschepost.de',
				'https://externalframe.gkplattform-prod-dmz.azure.deutschepost.de',
				'https://admindesktop.gkp.azure.deutschepost.de'
			],
			targetOrigin: '',
			iframeSelector: 'body.iframe',
			height: 0,
			width: 0
		},

		/**
		 * Create the component if our pages will be integrated inside an IFrame with (at least) the '.data'-selector.
		 *
		 * @private
		 */
		_create: function _create() {
			ui.merge('iframe', this);
			// Check if the page is really integrated as an IFrame.
			if (self !== top) {
				this.init();
			} else {
				console.log('This page did not render inside an iframe.');
			}
		},

		/**
		 * Initialize the component's necessary functions.
		 */
		init: function init() {
			var _this = this;
			this.attachEvents();
			this.requestOrigin();
			// Establish a promise because posting message relies on setting the target origin.
			var initPromise = new Promise(function (resolve) {
				// Set the 'targetOrigin' after a certain timeout if no origin has been received. This will be the case if a page will
				// be included outside the GK-P.
				setTimeout(function () {
					if (!_this.options.targetOrigin) {
						_this._setTargetOrigin();
						// Resolve the promise after setting the target origin.
						resolve();
					}
				}, 5000);
			});
			initPromise.then(function () {
				_this.initialBatchPost();
			});
			// Retrieve the configured width of the IFrame through our <body>-element.
			this.options.width = $(_this.options.iframeSelector).width();
		},

		/**
		 * Set the 'targetOrigin' used for the 'postMessage()' function.
		 *
		 * @param gkpOrigin The received 'targetOrigin' from the GK-P.
		 *
		 * @returns {string} The received 'gkpOrigin' or the current location of the parent if the domain matches.
		 * @private
		 */
		_setTargetOrigin: function _setTargetOrigin(gkpOrigin) {
			try {
				if (gkpOrigin) {
					this.options.targetOrigin = gkpOrigin;
				} else if (window.parent && window.parent.location) {
					this.options.targetOrigin = window.parent.location.protocol + '//' + window.parent.location.host;
				}
				return this.options.targetOrigin;
			} catch (error) {
				if (_instanceof(error, SyntaxError)) {
					return this.options.targetOrigin;
				}
			}
		},

		/**
		 * Request the 'targetOrigin' from the GK-P.
		 */
		requestOrigin: function requestOrigin() {
			var data = {
				function: 'requestOrigin'
			};
			// It is okay to use the wildcard ('*') in this situation because we do not know the GK-P origin initially. Afterwards, all
			// communication will be made using the retrieved origin.
			window.parent.postMessage(data, '*');
		},

		/**
		 * Request the data from the input fields of a form and post them via 'postMessage()' to the parent.
		 */
		requestData: function requestData() {
			if (this.options.targetOrigin) {
				var inputFields = $(
					'.form-group input:text, .datefield.form-group input:hidden, .hidden-field input:hidden, .form-group input:password,' +
					'.form-group input:checkbox, .form-group input:radio');
				var argumentsList = [];
				for (var i = 0; i < inputFields.length; i++) {
					if (inputFields[i].name) {
						argumentsList.push(inputFields[i].name);
					}
				}
				var data = {
					function: 'requestData',
					arguments: argumentsList
				};
				window.parent.postMessage(data, this.options.targetOrigin);
			}
		},

		/**
		 * Prefill an integrated form. This will be triggered upon receiving a 'postMessage()'-call from the parent.
		 *
		 * @param event The event received via 'postMessage()'.
		 */
		prefillForm: function prefillForm(event) {
			if (event.origin.indexOf(this.options.targetOrigin) > -1) {
				var data = event.data;
				if (data && data.arguments) {
					data.arguments.map(function (argument) {
						var keys = Object.keys(argument);
						for (var i = 0; i < keys.length; i++) {
							var el = $('input[name=\'' + keys[i] + '\']');
							for (var j = 0; j < el.length; j++) {
								if (el[j].type === 'checkbox' && argument[keys[i]] === 'Ja') {
									el[j].checked = true;
								}
								if (el[j].type === 'radio' && argument[keys[i]] === el[j].value) {
									el[j].checked = true;
								} else {
									if (argument[keys[i]] && argument[keys[i]].length) {
										el[j].value = argument[keys[i]];
									}
								}
							}
						}
					});
				}
			} else {
				console.warn('This event has been dispatched by an untrusted domain: ', event);
			}
		},

		/**
		 * Send a 'heartbeat' message to keep up the communication/session to GK-P.
		 */
		postAliveStatus: function postAliveStatus() {
			if (this.options.targetOrigin) {
				var data = {
					function: 'keepAlive',
					arguments: ['iFrame']
				};
				window.parent.postMessage(data, this.options.targetOrigin);
			}
		},

		/**
		 * Send the current height of the IFrame's content. Needed for resizing the IFrame properly according to its content.
		 *
		 * @returns {*} The current height of the IFrame's content.
		 */
		postHeight: function postHeight() {
			var height = $(this.options.iframeSelector).height();
			if (this.options.targetOrigin && (height !== this.options.height)) {
				var data = {
					function: 'resizeIframe',
					arguments: [height]
				};
				window.parent.postMessage(data, this.options.targetOrigin);
				this.options.height = height;
			}
			return height;
		},

		/**
		 * Batch the 'initial' postMessage()-events into one function.
		 */
		initialBatchPost: function initialBatchPost() {
			this.postAliveStatus();
			this.postHeight();
			this.requestData();
		},

		/**
		 * Attach necessary events for the message handling between the parent window and the integrated page (via IFrame).
		 */
		attachEvents: function attachEvents() {
			var _this = this;
			window.addEventListener('message', function (event) {
				// Receive the 'targetOrigin' from the GK-P after requesting it.
				if (event.data.function && event.data.function === 'gkpOrigin' && event.data.gkpOrigin) {
					// Check if the origin matches the list of whitelisted domains.
					if (_this.options.whitelist.includes(event.origin) && _this.options.whitelist.includes(event.data.gkpOrigin)) {
						_this._setTargetOrigin(event.data.gkpOrigin);
						// Send all necessary postMessage()-events after retrieving the 'targetOrigin' from the GK-P.
						_this.initialBatchPost();
					}
				}
				// Handle the received 'responseData' to prefill included forms.
				if (event.data.function && event.data.function === 'responseData') {
					_this.prefillForm(event);
				}
			}, false);

			$(window).on('click touchend', function () {
				_this.postAliveStatus();
			});

			var blackListEvent = ['onsearch', 'oncanplay', 'oncanplaythrough', 'oncontextmenu', 'ondblclick',
				'ondrag', 'ondragend', 'ondragenter', 'ondragleave', 'ondragover', 'ondragstart',
				'ondrop', 'onmousedown', 'onmouseenter', 'onmouseleave', 'onmousemove', 'onmouseout',
				'onmouseover', 'onmouseup', 'onmousewheel', 'onpause', 'onplay', 'onplaying',
				'onvolumechange', 'onpointerdown', 'onlostpointercapture', 'ongotpointercapture',
				'onpointermove', 'onpointerup', 'onpointercancel', 'onpointerover', 'onpointerout',
				'onpointerenter', 'onpointerleave', 'onafterprint', 'onbeforeprint', 'onkeydown',
				'onkeypress', 'onkeyup', 'oncuechange', 'ondurationchange', 'onloadstart',
				'onloadedmetadata', 'onloadeddata', 'onwheel', 'onscroll', 'onselect',
				'ontransitionstart', 'ontransitionrun', 'ontransitionend', 'ontransitioncancel'];
			var allEvents = [];
			for (var key in window) {
				if (key.indexOf('on') === 0) {
					if (!blackListEvent.includes(key)) {
						allEvents.push(key.slice(2));
					}
				}
			}
			var allEventsStr = allEvents.join(' ');
			$(window).on(allEventsStr, function (event) {
				// The height of the <body> inside the IFrame doesn't change immediately after a 'resize'-event in some cases. Therefore,
				// we should wait some milliseconds before retrieving the actual height. This is a workaround and will only trigger on
				// changes of the width.
				var height;
				var width = $(_this.options.iframeSelector).width();
				if (event.type === 'resize' && width !== _this.options.width) {
					_this.options.width = width;
					setTimeout(function () {
						height = _this.postHeight();
					}, 210);
				} else {
					height = _this.postHeight();
				}
				_this.recursivePostHeight(height);
			});
		},

		/**
		 * Post the height recursively according to its previous height.
		 *
		 * @param previousHeight The previous height.
		 */
		recursivePostHeight: function recursivePostHeight(previousHeight) {
			var _this = this;
			setTimeout(function () {
				var currentHeight = _this.postHeight();
				if (currentHeight !== previousHeight) {
					_this.recursivePostHeight(currentHeight);
				}
			}, 33);
		}
	});
}(ui.$));
