/* global ui */

(function ($) {
	$.widget('ipnp.flyin', {

		options: {
			timeout: null
		},

		_create() {
			this.element.hide();
			ui.sub('text.flyin', (event, data) => {
				// cancel previous animations
				this.element.stop();
				this.element.find('.flyin__text').html(data.text);
				this._show(data);
			});
		},

		_show(data) {
			// If delay is not defined or out of any value, use a timeout of '5000' instead
			this.options.timeout = data.delay || 5000;
			this.element.slideDown('slow').delay(this.options.timeout).slideUp('slow', () => {
				this.element.hide();
			});
		}
	});
}(ui.$));
