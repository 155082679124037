/* global ui */

/**
 * A widget for global login functionality
 */
(function ($) {
	$.widget('ipnp.ssoCiam', {

		previousAuthenticationLevel: 1,
		cachedUserInfo: {},
		pkUiJwtEndpoint: '/int-stammdaten/public/postnumber',
		options: {
			ciamActive: false,
			anonymous: false,
			// 1000 * 60 * 50 = 50min -> 30% = 15min
			timeout: 3000000,
			tid: null,
			// 1000 * 5 = 5sec
			intervalCountDown: 5000,
			tidInteraction: null,
			// 1000 * 60 = 1min
			intervalInteraction: 60000,
			limitDate: null,
			expiryDate: null,
			lastInteraction: null,
			cookieName: 'dhli',
			// 30%
			darkswitchRatio: 0.3
		},
		ciamOptions: {
			authenticationLevel: 0,
			requireUserInput: false,
			authenticationMethod: ['pwd'],
			headerID: '',
			appHeaderID: '',
			footerID: '',
			allowRegistration: false,
			username: ''
		},

		/**
		 * Creates the widget
		 */
		_create() {
			window.dhl.sso = this;
			ui.register({
				widget: 'navigationLogin'
			});
			ui.widgets.navigationLogin.init();
			ui.register({
				widget: 'bonus-points',
				sel: '.bonus-points'
			});
			ui.widgets['bonus-points'].init();

			// 10 * 500ms -> 5s maximum waiting time for ciam-object availability
			let _maxIntervalCount = 10;
			const _int = window.setInterval(() => {
				if (window.ciam) {
					window.clearInterval(_int);
					// Manually dispatching in the actual callback functions - see below
					window.ciam.notifyOnChange((eventCategory, userInfo) => {
						ui.log(`notifyOnChange: ${eventCategory}`);
						if (eventCategory.USER_MASTERDATA_CHANGE || eventCategory.LOGIN || eventCategory.LOGOUT
							|| eventCategory.AUTH_LEVEL_CHANGE || eventCategory.TOKEN_VALIDITY_CHANGE) {
							this._dispatchLoginChanged(userInfo);
						}
					});
					window.ciam.getUserInfo().then((userInfo) => {
						this._dispatchLoginChanged(userInfo);
					}).catch((error) => {
						ui.log('[CIAM ERROR]: An error occurred during the retrieval of the user info!', error);
					});
					ui.pub('login.initAutoRedirect');
					ui.pub('login.initAutoLogin');
				}
				_maxIntervalCount -= 1;
				if (_maxIntervalCount <= 0) {
					window.clearInterval(_int);
				}
			}, 500, this);

			ui.sub('login.startCountDown', (e, data) => {
				if (data.anonymous === 'true') {
					this.cachedUserInfo.expiryDate = ui.$.now() + this.options.timeout;
					this._refreshDSTimeout(this.cachedUserInfo);
				} else {
					this._refreshDSTimeout(this.cachedUserInfo);
				}
				// Timer Countdown 5sec
				window.clearTimeout(this.options.tid);
				this.options.tid = window.setInterval(this._checkCountdown, this.options.intervalCountDown, this);
				// Timer User Interaction 1min
				window.clearTimeout(this.options.tidInteraction);
				this.options.tidInteraction = window.setInterval(this._checkInteraction, this.options.intervalInteraction, this);
				const body = ui.$('body');
				body.unbind('click.darkswitch');
				body.unbind('tap.darkswitch');
				body.on('click.darkswitch tap.darkswitch', () => {
					this._userInteraction();
				});
			});
			ui.sub('login.activateAnonymous', () => {
				this.options.anonymous = true;
			});
		},

		/**
		 * Delivers the user ID
		 */
		getUserID() {
			return this._getUserDetail('userId', null);
		},

		/**
		 * Delivers the user name
		 */
		getUserName() {
			return this._getUserDetail('displayName', null);
		},

		/**
		 * Delivers the user post number
		 */
		getPostNumber() {
			return this._getUserDetail('postNumber', 0);
		},

		/**
		 * Delivers the authentication level
		 */
		getAuthenticationLevel() {
			return this._getUserDetail('authenticationLevel', 0);
		},

		/**
		 * Delivers 'true' if the user is logged in
		 */
		isLoggedIn(aLevel) {
			let level = aLevel;
			if (!level || typeof level !== 'number') {
				level = 2;
			}
			return (this.getAuthenticationLevel() >= level);
		},

		/**
		 * normal login
		 */
		login(authenticationLevel, headerID, footerID, loginSuccessLinkTarget, loginAbortedLinkTarget) {
			if (window.ciam) {
				/**
				 * Only Level 2 and 3 are allowed to authenticate, as described here: confluence.dhl.com/x/K3yuBQ
				 */
				if (authenticationLevel < 2 || authenticationLevel > 3) {
					ui.log('EXCEPTION: Invalid Authentication Level');
				}
				ui.pub('tracking.trackLayer', { name: '#login', state: 'open' });
				this.ciamOptions = {
					...this.ciamOptions, authenticationLevel, headerID, footerID
				};
				window.ciam.authenticate(this.ciamOptions).then(() => {
					ui.pub('tracking.trackLayer', { name: '#login', state: 'closed' });
					if (loginSuccessLinkTarget !== undefined && loginSuccessLinkTarget !== null) {
						window.location = loginSuccessLinkTarget;
					}
				}).catch((error) => {
					ui.log('[CIAM ERROR] An error occurred during the login:', error);
					if (loginAbortedLinkTarget !== undefined && loginAbortedLinkTarget !== null) {
						window.location = loginAbortedLinkTarget;
					}
				});
			}
		},

		/**
		 * Triggers the logout of the user
		 */
		logout(logoutText, logoutSuccessLinkTarget) {
			if (window.ciam) {
				window.ciam.logout(this.ciamOptions).then(() => {
					if (logoutSuccessLinkTarget) {
						window.location = logoutSuccessLinkTarget;
					}
					ui.pub('text.flyin', { text: logoutText || 'Logged out!' });
				}).catch((error) => {
					ui.log('[CIAM ERROR] An error occurred during the logout:', error);
				});
			}
		},

		/**
		 * Retrieve a JWT token for the corresponding "Postnummer".
		 *
		 * Hint: The endpoint answers with HTTP status code 200 and the token if logged in (WG >= 2), otherwise returns 401.
		 *
		 * @returns {null|string} The JWT token as string, otherwise null.
		 * @private
		 */
		async getPkUiJwtToken() {
			return await fetch(this.pkUiJwtEndpoint)
				.then(response => {
					if (response.ok) {
						return response.text();
					} else {
						return null;
					}
				})
				.then(responseText => {
					return responseText;
				})
				.catch(error => {
					ui.log('ERROR', `An error occurred fetching the JWT token: ${error}`);
				});
		},

		/**
		 * Internal function to deliver a single property
		 */
		_getUserDetail(prop, emptyValue) {
			if (!this.cachedUserInfo) {
				return null;
			}
			if (Object.prototype.hasOwnProperty.call(this.cachedUserInfo, prop)) {
				return this.cachedUserInfo[prop];
			}
			return emptyValue;
		},

		/**
		 * Dispatch a login changed notification,
		 * but only if new auth-level is different than old auth-level to prevent
		 * unnecessary follow-up actions.
		 * ui.pub('login.changed'); should never be dispatched manually
		 */
		_dispatchLoginChanged(userInfo) {
			if (!userInfo) {
				return;
			}
			if (userInfo.authenticationLevel === 2 || userInfo.authenticationLevel === 3) {
				// Get additional user data from PriKuS
				$.ajax({
					url: '/.customerInformation',
					dataType: 'json',
					async: false,
					success(data) {
						if (data.POSTNUMMER) {
							userInfo.postNumber = data.POSTNUMMER;
						}
					},
					error(jqXHR, textStatus) {
						ui.log('Failed to load user data', textStatus);
					}
				});
			} else {
				// Clear additional user data if user has an authentication level below WG2
				userInfo.displayName = '';
				userInfo.postNumber = 0;
			}
			this.ciamOptions = { ...this.ciamOptions, authenticationLevel: userInfo.authenticationLevel };
			const { expiryDate } = userInfo;
			this._handleDarkswitch();
			ui.$.extend(this.cachedUserInfo, userInfo);
			this.cachedUserInfo.expiryDate *= 1000;
			const offset = new Date(this.cachedUserInfo.expiryDate).getTimezoneOffset();
			this.cachedUserInfo.expiryDate += offset;
			if (this.ciamOptions.authenticationLevel !== this.previousAuthenticationLevel) {
				this.previousAuthenticationLevel = this.ciamOptions.authenticationLevel;
				ui.pub('login.changed', this.cachedUserInfo);
			} else {
				ui.log(`not dispatching event as new level == old level (${this.ciamOptions.authenticationLevel} = ${
					this.previousAuthenticationLevel})`);
			}
			if (typeof this.ciamOptions.authenticationLevel === 'undefined'
				|| this.ciamOptions.authenticationLevel < 3
				|| typeof expiryDate === 'undefined') {
				this.options.ciamActive = false;
				// Only if anonymous darkswitch is active
				if (this.options.anonymous) {
					ui.pub('login.startCountDown', { anonymous: 'true' });
				} else {
					window.clearTimeout(this.options.tid);
					window.clearTimeout(this.options.tidInteraction);
				}
			} else {
				ui.pub('login.startCountDown', { anonymous: 'false' });
				this.options.ciamActive = true;
			}
		},

		_handleDarkswitch() {
			if (this.ciamOptions.authenticationLevel === 3) {
				ui.pub('login.switchprivacy', { stage: '2' });
			} else if (this.previousAuthenticationLevel === 3) {
				if (this.ciamOptions.authenticationLevel === 2) {
					ui.pub('login.switchprivacy', { stage: '3' });
				} else {
					ui.pub('login.switchprivacy', { stage: '4' });
				}
			} else {
				ui.pub('login.switchprivacy', { stage: '1' });
			}
		},

		_refreshDSTimeout(userInfo) {
			if (!userInfo) {
				return;
			}
			const _this = this;
			if (!userInfo.expiryDate) {
				userInfo.expiryDate = ui.$.now() + _this.options.timeout;
			}
			// sec vs. mSec
			if (userInfo.expiryDate < ui.$.now()) {
				userInfo.expiryDate *= 1000;
			}
			const diffTime = Math.floor((userInfo.expiryDate - ui.$.now()) * _this.options.darkswitchRatio);
			_this.options.limitDate = ui.$.now() + diffTime;
			_this.options.expiryDate = userInfo.expiryDate;
			ui.log(`expiryDate: ${new Date(userInfo.expiryDate)}`);
			ui.log(`nowDate:    ${new Date(ui.$.now())}`);
			ui.log(`limitDate:  ${new Date(_this.options.limitDate)}`);
		},

		_checkCountdown(_this) {
			const _that = _this;
			// Countdown for the darkswitch
			const diffTimeLim = (_that.options.limitDate - ui.$.now()) / 1000;
			ui.log(`${Math.floor(diffTimeLim / 60)}min ${Math.floor(diffTimeLim % 60)}sec to Darkswitch...`);
			if (_that.options.ciamActive) {
				// Countdown for the logout
				const diffTimeEx = ((_that.options.expiryDate) - ui.$.now()) / 1000;
				ui.log(`${Math.floor(diffTimeEx / 60)}min ${Math.floor(diffTimeEx % 60)}sec to Logout...`);
			}
			// Timeout -> Show darkswitch
			if (_that.options.limitDate < ui.$.now()) {
				ui.pub('login.showprivacy');
			}
		},

		_checkInteraction(_this) {
			ui.log('###########checkInteraction!');
			const _that = _this;
			// New interaction since last check?
			if ((ui.$.now() - _that.options.intervalInteraction) < _that.options.lastInteraction) {
				ui.log('###########InteractionFound->refreshToken!');
				if (window.ciam) {
					window.ciam.refreshToken().then((userInfo) => {
						_that._refreshDSTimeout(userInfo);
					}).catch((error) => {
						ui.log('[CIAM ERROR] An error occurred during refreshing the user master data:', error);
					});
				}
			}
		},

		_userInteraction() {
			ui.log('###########Interaction!');
			const _this = this;
			_this.options.lastInteraction = ui.$.now();
		}
	});
}(ui.$));
