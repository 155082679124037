/* global ui */

(function ($) {
	$.widget('ipnp.circle-icon-expandable', {

		options: {
			bodySelector: '.circle-icon-expandable__body',
			iconSelector: '.circle-icon__icon',
			iconActiveClass: 'circle-icon__icon--active',
			bodyShowClass: 'show'
		},

		_create() {
			this.element.find(this.options.bodySelector).on('show.bs.collapse', (e) => {
				this._setCircleIconActive(e.currentTarget);
				this.element.parent().addClass('mb-0');
			});

			this.element.find(this.options.bodySelector).on('hide.bs.collapse', (e) => {
				this._setCircleIconInactive(e.currentTarget);
				this.element.parent().removeClass('mb-0');
			});

			// stop videos
			if (this.element.find('.video').length) {
				const video = this.element.find('iframe')[0];
				if (video) {
					video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
				}
			}

			if (ui.lib.isEditMode()) {
				this.element.find(this.options.bodySelector).first().collapse('show');
			}
		},

		/**
		 * Add classes to display circle icon in active state
		 * @param expandableBody container of circle icon which expand
		 * @private
		 */
		_setCircleIconActive(expandableBody) {
			this.element.find(this.options.bodySelector).removeClass(this.options.bodyShowClass);
			this.element.find(`.${this.options.iconActiveClass}`).removeClass(this.options.iconActiveClass);
			const circleIconId = expandableBody.getAttribute('id');
			$(`a[href="#${circleIconId}"]:visible`).find('.circle-icon__icon').addClass(this.options.iconActiveClass);
		},

		/**
		 * Show default design of circle icon
		 * @param expandableBody container of circle icon which expand
		 * @private
		 */
		_setCircleIconInactive(expandableBody) {
			const circleIconId = expandableBody.getAttribute('id');
			$(`a[href="#${circleIconId}"]:visible`).find('.circle-icon__icon').removeClass(this.options.iconActiveClass);
		}
	});
}(ui.$, window));
