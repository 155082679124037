/* global ui, OnetrustActiveGroups */

(function ($) {
	$.widget('ipnp.stage', {

		options: {
			cookieName: 'dhlssw',
			cookieExpiryDate: 1,
			stageItems: {},
			pegaStageItems: {},
			renderHeadlineAsH1: false,
			consentCategory: 'C0004'
		},

		_create() {
			const stageItemList = document.querySelectorAll('.stage-item');
			ui.merge('ipnp.stage', this, {
				data: () => {
					const stageItemsConfig = { stageItems: {} };
					for (const stageItem of Object.values(stageItemList)) {
						const { stageItemId } = stageItem.dataset;
						const stageItemConfig = ui.widgets['ipnp.stage'].config[stageItemId];
						Object.assign(stageItemsConfig.stageItems, { [stageItemId]: stageItemConfig });
					}
					return stageItemsConfig;
				}
			});
			this.options.renderHeadlineAsH1 = !!$(this.element).data('headline-as-h1');
			const stageWithPega = Object.values(this.options.stageItems).some(stageItem => stageItem.use_pega === 'true');
			if (stageWithPega) {
				this.options.pegaStageItems = JSON.parse(JSON.stringify(this.options.stageItems));
				void this._handlePegaResponse();
				ui.sub('login.changed', () => {
					void this._handlePegaResponse();
				});
			}
			this._renderStage(this.options.stageItems, false);
		},

		/**
		 * Method to render the stage dynamically because the rendering depends on a randomization, which is why it cannot be rendered on
		 * the server side.
		 *
		 * @param stageItems List of stage items - differs depending on PEGA.
		 * @param refreshItem 'true' if the stage items should be deleted because of PEGA exchange.
		 * @private
		 */
		_renderStage(stageItems, refreshItem) {
			const _this = this;
			if (!ui.lib.isEditMode()) {
				if (Object.keys(stageItems).length > 2) {
					// Sort stage items by weight.
					const sortedStageItems = this._sortStageItemsByWeight(stageItems);
					const highestWeightItemId = [...sortedStageItems][0][0];
					sortedStageItems.forEach((value, key) => {
						const stageItemObj = _this._getStageItemById(key, stageItems);
						if (key === highestWeightItemId) {
							_this._renderFirstStage(stageItemObj, refreshItem);
						} else {
							_this._renderReferenceStage(stageItemObj, refreshItem);
						}
					});
				} else {
					// Show the first stage item in stage area.
					const stageItemObj = _this._getStageItemById(Object.keys(stageItems)[0], stageItems);
					this._renderFirstStage(stageItemObj, refreshItem);
				}
			}
		},

		/**
		 * Method to render the stage item in stage area
		 *
		 * @param stageItemObj The object with the data of the current stage item.
		 * @param refreshItem 'true' if the stage items should be deleted because of PEGA exchange.
		 * @private
		 */
		_renderFirstStage(stageItemObj, refreshItem) {
			if (refreshItem) {
				const firstStageItem = this.element[0].querySelector('.stage-item .stage-item__link');
				if (firstStageItem !== null) {
					firstStageItem.remove();
				}
			}
			const $stageItem = this._fillTemplateWithData(stageItemObj);
			if (this.options.renderHeadlineAsH1) {
				$stageItem.find('.stage-item__title').replaceWith('<h1 class="stage-item__title"></h1>');
			}
			$stageItem.find('.stage-item__title').html(stageItemObj.headline);
			$stageItem.find('.stage-item__title').addClass(stageItemObj.headline_color);
			$stageItem.find('.stage-item__text').addClass(stageItemObj.subheadline_color);
			const $stageItemCta = $stageItem.find('.stage-item__cta');
			if ($stageItemCta.text().length === 0) {
				$stageItemCta.hide();
			}
			$stageItem.appendTo(this.element.find('.stage-item').first());
		},

		/**
		 * Method to render the stage items in stage reference
		 *
		 * @param stageItemObj The object with the data of the current stage item.
		 * @param refreshItem 'true' if the stage items should be deleted because of PEGA exchange.
		 * @private
		 */
		_renderReferenceStage(stageItemObj, refreshItem) {
			if (refreshItem) {
				const referenceStageItem = document.querySelector('.stage-reference .stage-item');
				if (referenceStageItem !== null) {
					referenceStageItem.remove();
				}
			}
			const $stageItem = this._fillTemplateWithData(stageItemObj);
			$stageItem.find('.stage-item__title').html(stageItemObj.headline);
			$('<div class="stage-item col-xs-12 col-sm-6"></div>').appendTo('.stage-reference').append($stageItem);
		},

		/**
		 * Method to fill the stage-item data to the template
		 *
		 * @param stageItemObj The object with the data of the current stage item.
		 * @private
		 */
		_fillTemplateWithData(stageItemObj) {
			// clone image border stage-item template
			const templateStageItem = this.element.find('template.stage-item__template')[0].content;
			// clone clean stage-item markup and fill its content with stage item content
			const $stageItem = $(templateStageItem).clone();
			// image
			$('<img/>').appendTo($stageItem.find('.stage-item__image'));

			const image_alt_unescaped = this._unescapeHtml(stageItemObj.image_alt);
			const image_title_unescaped = this._unescapeHtml(stageItemObj.image_title);
			$stageItem.find('img').attr({
				src: stageItemObj.image_url,
				alt: image_alt_unescaped,
				title: image_title_unescaped
			});
			$stageItem.find('img').css({
				'object-position': `${stageItemObj.alignment} top`
			});
			// Gradient
			if (stageItemObj.gradient === 'true') {
				$stageItem.find('.stage-item__image').after('<div class="stage-item__gradient"></div>');
			}

			// remove &amp; from url and replace it with the proper & character
			const replacedUrl = stageItemObj.link_url.replace(/&amp;/g, '&');

			// link
			if (!!(replacedUrl)) {
				$stageItem.find('a').attr({
					href: replacedUrl,
					target: stageItemObj.link_in_new_tab,
					title: stageItemObj.linktext
				});
			} else {
				$stageItem.find('a').contents().unwrap();
			}

			// linktext
			$stageItem.find('.stage-item__cta').html(stageItemObj.button_text);
			// text
			$stageItem.find('.stage-item__text').html(stageItemObj.subheadline);
			return $stageItem;
		},

		/**
		 * Calculate weighting of stage items by random and sort them.
		 * Store weight in cookie on first page load or get a list of sorted stage items from this cookie.
		 *
		 * @param stageItemsObj Object holding the stage items.
		 * @return A map with sorted stage item objects.
		 * @private
		 */
		_sortStageItemsByWeight(stageItemsObj) {
			const weightMap = new Map();
			let sortMap;
			// Load weight of stage items from cookie if it exists.
			ui.lib.cookie.get({
				name: this.options.cookieName,
				callback: (stageItemWeights) => {
					const cookieMap = new Map(JSON.parse(stageItemWeights));
					const correctCookieMap = Object.keys(this.options.stageItems).every((stageItemKey) => cookieMap.has(stageItemKey));
					sortMap = correctCookieMap ? cookieMap : new Map();
				}
			});
			// Calculate randomized weight if no cookie exists (first page visit).
			if (sortMap.size === 0) {
				for (const [key, value] of Object.entries(stageItemsObj)) {
					const stageItemId = key;
					const stageItemWeight = value.weight;
					const randomWeight = Math.random() * stageItemWeight;
					weightMap.set(stageItemId, randomWeight);
					// Sort the map only if exactly three items exist.
					if (weightMap.size === 3) {
						sortMap = new Map([...weightMap].sort((a, b) => b[1] - a[1]));
						let consentOk = false;

						// Saves stage item weight in cookie only if one trust consent category accepted. If not show stage item random.
						const oneTrustIntervall = window.setInterval(() => {
							if (this.options.consentCategory && typeof (OnetrustActiveGroups) !== 'undefined') {
								window.clearInterval(oneTrustIntervall);
								if (OnetrustActiveGroups.indexOf(this.options.consentCategory) >= 0) {
									consentOk = true;
								}

								if (consentOk) {
									ui.lib.cookie.set({
										name: this.options.cookieName,
										value: JSON.stringify(Array.from(sortMap.entries())),
										days: this.options.cookieExpiryDate,
										path: window.location.pathname
									});
								}
							}
						}, 50);

						window.setTimeout(() => {
							window.clearInterval(oneTrustIntervall);
						}, 3000);
					}
				}
			}
			return sortMap;
		},

		/**
		 * Create stage item object with configured dialog properties and return this object.
		 *
		 * @param stageItemId ID of stage item.
		 * @param stageItems The list of configured stage items (might be customized by PEGA).
		 * @return A stage item object.
		 * @private
		 */
		_getStageItemById(stageItemId, stageItems) {
			return stageItems[stageItemId];
		},

		/**
		 * Trigger and handle the exchange of the content for the corresponding stage item via PEGA. Function is 'async' in order to wait
		 * for fetching the JWT token for PK.
		 *
		 * @returns {Promise<void>} Promise returns 'undefined', therefore, it can be ignored using 'void'.
		 * @private
		 */
		async _handlePegaResponse() {
			if (!ui.lib.isAuthorInstance()) {
				for (const stageItemObj of Object.values(this.options.pegaStageItems)) {
					const usePega = stageItemObj.use_pega;
					const pegaEnvironment = stageItemObj.pega_environment;
					const pegaContainerId = stageItemObj.pega_container_id;
					if (usePega === 'true' && pegaEnvironment && pegaContainerId && pega) {
						const pegaOffer = await pega.getPegaOffer(pegaEnvironment, pegaContainerId);
						this._handlePegaOffer(pegaOffer, stageItemObj);
					}
				}
			}
		},

		/**
		 * Handle the offer from PEGA to exchange content of the stage item.
		 *
		 * @param pegaOffer The offer received from calling PEGA.
		 * @param stageItemObj The object with the data of the current stage item.
		 * @private
		 */
		_handlePegaOffer(pegaOffer, stageItemObj) {
			if (pegaOffer !== undefined && pegaOffer !== null) {
				// Exchange the image.
				if (pegaOffer.ImageURL) {
					stageItemObj.image_url = pegaOffer.ImageURL;
				}
				// Exchange the title.
				if (pegaOffer.ShortDescription) {
					stageItemObj.headline = pegaOffer.ShortDescription;
				}
				// Exchange the text.
				if (pegaOffer.Benefits) {
					stageItemObj.subheadline = pegaOffer.Benefits;
				}
				// Exchange the link.
				if (pegaOffer.ClickThroughURL) {
					stageItemObj.link_url = pegaOffer.ClickThroughURL;
				}
				this._renderStage(this.options.pegaStageItems, true);
			} else {
				this._renderStage(this.options.stageItems, true);
			}
		},

		/**
		 * Unescape a string which contains HTML entities.
		 *
		 * Do not use jQuery and stick to a textarea in order to be sure to not introduce any XSS vulnerability.
		 *
		 * @param encodedString String with potential html entities (ampersand)
		 * @return Decoded string
		 * @private
		 * @see https://stackoverflow.com/questions/1147359/how-to-decode-html-entities-using-jquery/1395954#1395954
		 */
		_unescapeHtml(encodedString) {
			if (!!encodedString) {
				const textArea = document.createElement('textarea');
				textArea.innerHTML = encodedString;
				return textArea.value;
			}
			return encodedString;
		}
	});
}(ui.$, window));
