/* global ui, OneTrust, OnetrustActiveGroups */

(function ($) {
	$.widget('ipnp.pega-pk-cookie', {

		options: {
			consentCategory: 'C0004'
		},

		_create() {
			let consentOk = false;
			const oneTrustIntervall = window.setInterval(() => {
				if (this.options.consentCategory && typeof (OnetrustActiveGroups) !== 'undefined') {
					window.clearInterval(oneTrustIntervall);
					if (OnetrustActiveGroups.indexOf(this.options.consentCategory) >= 0) {
						consentOk = true;
					}

					if (consentOk) {
						if (crypto && document.cookie.indexOf(pega.pegaPkCookieName) === -1) {
							ui.lib.cookie.set({
								name: pega.pegaPkCookieName,
								value: crypto.randomUUID(),
								days: 60,
								sameSite: 'Strict',
								secure: true
							});
						}
					}
				}
			}, 50);
		}
	});

}(ui.$));
