/* global ui */

(function ($) {
	$.widget('ipnp.cia', {

		options: {
			serviceId: null,
			errorLayer: null,
			elementName1: '',
			elementName2: '',
			validatorType1: '',
			validatorType2: ''
		},

		_create() {
			this.options.elementName1 = this.element.data("element-name1");
			this.options.elementName2 = this.element.data("element-name2");
			this.options.validatorType1 = this.element.data("validator1");
			this.options.validatorType2 = this.element.data("validator2");


			this.errorLayer = this.element.find('.cia__error-layer');
			this.searchInput = this.element.find('input');
			this.searchButton = this.element.find('button');
			this.searchInput.removeAttr('disabled');
			this.searchButton.removeAttr('disabled');

			this.searchButton.click(() => {
				this._prepareFormForSubmit(this.searchButton);
				this._formSubmit(this.searchButton, this.options);
			});
		},

		_prepareFormForSubmit($btn) {
			const $form = $btn.closest('form');
			const actionQueryParamString = $form.attr('action').split('?');
			let actionQueryParams = null;
			const pattern = /[<>]/i;

			if (actionQueryParamString[1]) {
				actionQueryParams = actionQueryParamString[1].split('&');
			} else {
				return;
			}

			for (let i = 0; i < actionQueryParams.length; ++i) {
				let param = actionQueryParams[i].split('=')[0];
				let value = actionQueryParams[i].split('=')[1];
				param = param.replace(pattern, '');
				value = value.replace(pattern, '');
				if ($form.find(`input[name="${param}"]`).length === 0) {
					$form.append(`<input type="hidden" name="${param}" value="${value}"/>`);
				}
			}
		},

		_formSubmit($btn, options) {
			// determine form dependent config for validation of inputs
			const $form = $btn.closest('form');
			if ($form && $form.length > 0) {
				const $searchField = $form.find('[type=\'search\']');
				const regex1 = new RegExp(options.validatorType1);
				const regex2 = new RegExp(options.validatorType2);
				if (regex1 && regex1.test($searchField.val()) && options.elementName1) {
					$searchField.attr('name', options.elementName1);
				} else if (regex2 && regex2.test($searchField.val()) && options.elementName2) {
					$searchField.attr('name', options.elementName2);
				} else {
					// Default backup - without parameter.
					$searchField.removeAttr('name');
				}
			}
		}
	});
}(ui.$, window));
