/* global ui */

(function ($) {
	$.widget('ipnp.dark-switch', {

		options: {
			modalClass: 'modal--darkswitch'
		},

		_create() {
			// Don't show dark switch on author instances.
			if (ui.lib.isEditMode()) {
				return;
			}

			const useAD = this.element.data('use-anonymous-dark-switch');
			if (useAD) {
				ui.pub('login.activateAnonymous');
				ui.pub('login.startCountDown', { anonymous: 'true' });
			}

			ui.sub('login.showprivacy', () => {
				this._show();
			});

			ui.sub('login.switchprivacy', (e, data) => {
				switch (data.stage) {
					case '1':
						ui.log('###########Switch to PrivacyText 1');
						$(`.${this.options.modalClass}`).find('.dark-switch__placeholder')
							.html(this.element.find('.dark-switch__stage1').html());
						break;
					case '2':
						ui.log('###########Switch to PrivacyText 2');
						$(`.${this.options.modalClass}`).find('.dark-switch__placeholder')
							.html(this.element.find('.dark-switch__stage2').html());
						break;
					case '3':
						ui.log('###########Switch to PrivacyText 3');
						$(`.${this.options.modalClass}`).find('.dark-switch__placeholder')
							.html(this.element.find('.dark-switch__stage3').html());
						break;
					case '4':
						ui.log('###########Switch to PrivacyText 4');
						$(`.${this.options.modalClass}`).find('.dark-switch__placeholder')
							.html(this.element.find('.dark-switch__stage4').html());
						break;
					default:
						ui.log('###########Switch to PrivacyText 1');
						$(`.${this.options.modalClass}`).find('.dark-switch__placeholder')
							.html(this.element.find('.dark-switch__stage5').html());
						break;
				}
			});
		},

		_show() {
			this.element.find('.btn').click((e) => {
				ui.stop(e);
				this._hide();

				const useAD = this.element.data('use-anonymous-dark-switch');
				if (useAD) {
					ui.pub('login.startCountDown', { anonymous: 'true' });
				} else {
					ui.pub('login.startCountDown', { anonymous: 'false' });
				}
			});

			const modalOptions = {
				closeButton: false,
				closeOnOutsideClick: false,
				keyboardNavigation: false,
				skin: 'dark',
				elements: [
					{
						content: this.element[0]
					}
				]
			};

			$.ipnp.Modal(modalOptions).showHtmlModal(this.options.modalClass, null, modalOptions.closeOnOutsideClick);
		},

		_hide() {
			ui.pub('ui.modal.close');
			ui.pub('ui.modal.destroy');
		}
	});
}(ui.$, window));
