/**
 * tracking widget
 *
 * Modifications of the datalayer should be done through this widget
 *
 */
(function ($) {

	'use strict'; /* global ui, $ */

	$.widget('ui.tracking', {

		options: {
			eventNameInteraction: 'dhldeUserInteraction'
		},

		// internal datalayer, is referenced from window.digitalData, all modifications should be done on this
		_dl: {},

		_create: function () {
			if (!ui.lib.isAuthorInstance()) {
				// set up datalayer with pre-existing values
				if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
					this._initDataLayer(window.digitalData.page.pageInfo);
				}

				// example: ui.pub('tracking.initDataLayer', {...});
				ui.sub('tracking.initDataLayer', (e, data) => {
					this._initDataLayer(data);
				});

				// example: ui.pub('tracking.updateApplicationData', {id: 'onlinefrankierung', object: {layer: 'test'}});
				ui.sub('tracking.updateApplicationData', (e, data) => {
					this._updateApplicationData(data);
				});

				// example: ui.pub('tracking.updateEvents', {navigationUsed: 'true'});
				ui.sub('tracking.updateEvents', (e, data) => {
					if (data.navigationUsed === 'true') {
						this._updateEvents('navigationUsed', true);
					}
				});

				// example: ui.pub('tracking.trackLayer', {name: '#login', state: 'open'});
				ui.sub('tracking.trackLayer', (e, data) => {
					if (data.name === '#login' && data.state === 'open') {
						this._updateEvents('loginLayerOpen', true);
					}
				});

				// listen for changes to login and refresh data from the corresponding objects
				ui.sub('login.changed', () => {
					this._refreshLoginData();
					this._sendEvent({ user: this._dl.user }, 'loginChanged');
				});

				// example: ui.pub('tracking.trackGenericEvent', {"eventName": "okaUserInteraction", "obj": {"oka": {"events":
				// ["checkboxSuccess"], "formElement": ["checkboxDatenSpeichern"]}}});
				ui.sub('tracking.trackGenericEvent', (e, data) => {
					this._sendEvent(data.obj, data.eventName);
				});
			}
		},

		/**
		 * inits the data layer
		 */
		_initDataLayer: function (data) {
			$.extend(true, this._dl, this.template);
			this._dl.page.pageInfo.pageName = data.pageName;
			this._dl.page.pageInfo.channel = data.channel;
			$.extend(true, window.digitalData, this._dl);
		},

		/**
		 * updates part of the digitalData-object with an
		 * object of the structure:
		 * {id: 'onlinefrankierung', object: {layer: 'test'}}
		 * id is the name of the block in the data-structure (1st level)
		 * object is the complete sub-object of the application
		 **/
		_updateApplicationData: function (data) {
			$.extend(true, this._dl[data.id], data.object);
			$.extend(true, window.digitalData[data.id], this._dl[data.id]);
		},

		/**
		 * updates the events array of the dhlde string,
		 * overwriting existing events // no event stacking
		 */
		_updateEvents: function (eventString, sendEvent) {
			if (this._dl.dhlde === undefined || this._dl.dhlde.length < 0) {
				this._dl.dhlde = {};
				this._dl.dhlde.events = [];
			}
			this._dl.dhlde.events.length = 0;
			this._dl.dhlde.events.push(eventString);

			if (sendEvent) {
				this._sendEvent({ dhlde: this._dl.dhlde });
			}

		},

		/**
		 * get sso data (from CIAM)
		 */
		_refreshLoginData: function () {
			if (dhl.sso) {
				ui.log('(ui.tracking) logged in: ' + dhl.sso.isLoggedIn());
				this._dl.user.login = dhl.sso.isLoggedIn();
				this._dl.user.id = dhl.sso.getUserID() || '';
				this._dl.user.type = dhl.sso.getAuthenticationLevel() || 0;
				$.extend(true, window.digitalData.user, this._dl.user);
			} else {
				ui.log('(ui.tracking) no sso');
			}
		},

		/**
		 * send event in the form of
		 * document.body.dispatchEvent(new CustomEvent("loginChanged", {
		 *     detail: {
		 *       user: {
		 *         id: "31b8754a-c7a5-4292-918e-f2e3d3338df5",
		 *         login: true,
		 *         type: 3
		 *        }
		 *      }
		 *   }));
		 *
		 * Debug with
		 * document.body.addEventListener('dhldeUserInteraction', function(event){ console.log(event.type, event.detail)});
		 */
		_sendEvent: function (obj, eventName) {
			let localObject = {};
			// create a copy of object in case access is delay / object changed
			$.extend(true, localObject, obj);
			document.body.dispatchEvent(
				new CustomEvent(eventName != null ? eventName : this.options.eventNameInteraction, { detail: localObject }));
		},

		/**
		 * template of complete datalayer as defined with 20170804_NEPAL_SD_Datalayer_v04
		 */
		template: {
			page: {
				pageInfo: {
					pageName: '',
					errorPage: '',
					channel: '',
					hierarchy: '',
					domain: ''

				},
				contentInfo: {
					onsiteSearch: '',
					onsiteSearchTerm: '',
					onsiteSearchResults: ''
				},
				attributes: {}
			},
			user: {
				id: '',
				login: '',
				type: 0
			},
			ipnp: {
				errorMessage: '',
				events: []
			},
			product: {},
			cart: {},
			transaction: {},
			onlinefrankierung: {},
			paketde: {},
			sendungsverfolgung: {},
			bonus: {}
		}
	});
}(ui.$, window));
