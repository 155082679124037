/* global ui */

(function ($) {
	$.widget('ipnp.navigation-mobile', {

		options: {
			classMobileUserMenuIn: 'mobile-user-menu--in',
			classMobileMenuIn: 'mobile-menu--in',
			classBlurBackground: 'blur-background'
		},

		_create() {
			const $body = $('body').eq(0);
			this._closeMenuOnClickOutside();

			const $mobileMenuButton = $('.header__menu-btn');
			$mobileMenuButton.click((e) => {
				e.preventDefault();
				this.element.toggleClass(this.options.classMobileMenuIn);
				$body.toggleClass(this.options.classBlurBackground);

				if (this.element.hasClass(this.options.classMobileUserMenuIn)) {
					this.element.toggleClass(this.options.classMobileUserMenuIn);
				}
			});

			const $mobileUserMenuButton = $('.user-menu__button');
			$mobileUserMenuButton.click((e) => {
				e.preventDefault();
				if ($body.hasClass(this.options.classBlurBackground)) {
					$body.toggleClass(this.options.classBlurBackground);
				}
				this.element.toggleClass(this.options.classMobileUserMenuIn);
				if (this.element.hasClass(this.options.classMobileMenuIn)) {
					this.element.toggleClass(this.options.classMobileMenuIn);
				}
			});

			ui.sub('popup.opened', () => {
				this._closeMobileMenu();
			});

			// toggle submenu dropdown slide in
			$('.sub-menu__dropdown').click(function (e) {
				e.preventDefault();
				$(this).parents('li').toggleClass('in');
			});

			this._mobileMenuSlideIn();
		},

		_closeMobileMenu() {
			this.element.removeClass(this.options.classMobileMenuIn);
			$('body').removeClass(this.options.classBlurBackground);

			if (this.element.hasClass(this.options.classMobileUserMenuIn)) {
				this.element.removeClass(this.options.classMobileUserMenuIn);
			}
		},

		_mobileMenuSlideIn() {
			const $primaryNavigationWrapper = $('.primary-navigation__wrapper');
			const $menuSlideIn = $primaryNavigationWrapper.find('.link-icon--slide-in');
			const $menuBackBtn = $primaryNavigationWrapper.find('.menu-back');
			const $linkWrapper = $primaryNavigationWrapper.find('.primary-navigation__tabs');

			$menuSlideIn.click(function (e) {
				e.preventDefault();
				$(this).parents('li').find('.sub-menu').toggleClass('sub-menu--slide-in');
				$($linkWrapper).toggleClass(this.options.classBlurBackground);
			});

			$menuBackBtn.click(function (e) {
				e.preventDefault();
				$(this).parents('ul.sub-menu').toggleClass('sub-menu--slide-in');
				$($linkWrapper).toggleClass(this.options.classBlurBackground);
			});
		},

		/**
		 * Closes the mobile menu if user clicked outside of menu
		 * @private
		 */
		_closeMenuOnClickOutside() {
			const $body = $('body');
			if (ui.lib.getBreakpoint() !== 'lg') {
				$('html').on('click', (e) => {
					if ($body.hasClass(this.options.classBlurBackground)) {
						// click outside of header menu
						if ($(e.target).closest('.header-wrap').length === 0) {
							this.element.toggleClass(this.options.classMobileMenuIn);
							$body.toggleClass(this.options.classBlurBackground);
						}
					}
				});
			}
		}
	});
}(ui.$));
