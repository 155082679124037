(function (ui) {
	/* global ui */

	if (window.location.pathname.indexOf('.data.') > -1) {
		ui.register({
			widget: 'iframe',
			sel: '.iframe'
		});

		ui.log('iframe : The following widgets have not been registered because the page request contains sling selector "data":');
		ui.log('iframe : darkswitch');
		ui.log('iframe : tracking');
		ui.log('iframe : ssoLogin');
	} else {
		/**
		 * The following widgets shall not be registered if the page is requested with sling selector "data"
		 */
		ui.register({
			widget: 'tracking',
			order: 2
		});

		ui.register({
			widget: 'ssoLogin',
			order: 4
		});

		ui.register({
			widget: 'dark-switch',
			sel: '.dark-switch'
		});
	}

	ui.register({
		widget: 'primary-navigation',
		sel: '.primary-navigation'
	});

	ui.register({
		widget: 'navigation-mobile',
		sel: '.header'
	});

	ui.register({
		widget: 'searchslot',
		sel: '.searchslot'
	});

	ui.register({
		widget: 'stage',
		sel: '.stage'
	});

	ui.register({
		widget: 'quickaccess',
		sel: '.quickaccess'
	});

	ui.register({
		widget: 'step-by-step',
		sel: '.step-by-step'
	});

	ui.register({
		widget: 'circle-icon-expandable',
		sel: '.circle-icon-expandable'
	});

	ui.register({
		widget: 'cia',
		sel: '.cia'
	});

	ui.register({
		widget: 'flyin',
		sel: '.flyin'
	});

	ui.register({
		widget: 'pega',
		order: 2
	});

	ui.register({
		widget: 'pega-pk-cookie',
		order: 1
	});

	ui.register({
		widget: 'pega-gk-cookie',
		sel: '[data-set-pega-cookie]',
		order: 1
	});

	ui.register({
		widget: 'teaser',
		sel: '.teaser'
	});

	ui.$(() => {
		ui.init();
	});
}(ui));
