/* global ui */

(function ($) {
	$.widget('ipnp.step-by-step', {
		_create() {
			// add additional container class for step-by-step component
			this.element.closest('.row').addClass('step-guide');
		}
	});
}(ui.$));
