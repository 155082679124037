/* global ui, dhl */

/**
 * A widget for global login functionality.
 */
(function ($) {
	const navigation = document.querySelector('.primary-navigation');

	$.widget('ipnp.navigationLogin', {

		options: {
			headerFragment: '',
			footerFragment: '',
			authenticationLevel: '',
			defaultText: '',
			autoRedirectEnabled: 'false',
			autoRedirectTargetPage: '',
			autoLoginEnabled: 'false',
			autoLoginHeaderFragment: '',
			autoLoginFooterFragment: ''
		},

		dataCiamLinkTarget: 'data-ciam-link-target',

		/**
		 * Creates the widget.
		 */
		_create() {
			if (navigation !== null) {
				this.options.headerFragment = navigation.dataset.headerFragment;
				this.options.loginFragment = navigation.dataset.footerFragment;
				this.options.authenticationLevel = navigation.dataset.autoLoginLevel;
				this.options.defaultText = navigation.dataset.loginDefaultText;
				this.options.autoRedirectEnabled = navigation.dataset.autoRedirect;
				this.options.autoRedirectTargetPage = navigation.dataset.autoRedirectPage;
				this.options.autoLoginEnabled = navigation.dataset.autoLoginStatus;
				this.options.autoLoginHeaderFragment = navigation.dataset.autoLoginHeaderFragment;
				this.options.autoLoginFooterFragment = navigation.dataset.autoLoginFooterFragment;

				this._registerLoginLogout();
				ui.sub('login.changed', () => {
					this._change();
				});
				this._change();

				if (this.options.autoRedirectEnabled === 'false' || this.options.autoRedirectEnabled === '') {
					ui.sub('login.initAutoLogin', () => {
						this._autoLogin();
					});
				}

				ui.sub('login.initAutoRedirect', () => {
					this._autoRedirect();
				});

				// sets focus to darkening mode if this visible
				$(window).on('focus', () => {
					if ($('#ciam-sdk-darkening').is(':visible') && !$('#ciam-sdk-darkening button').is(':focus')) {
						$('#ciam-sdk-darkening').focus();
					}
				});

				this._addListenersForUserMenuLinks();
			}
		},

		/**
		 * Refreshing personalized frontend displays.
		 *
		 * @private
		 */
		_change() {
			this._displayUsername();
			this._updatePostNumber();
			this._switchLoginLogout();
		},

		/**
		 * Shows/hides the login/logout button depending on the login state.
		 *
		 * @private
		 */
		_switchLoginLogout() {
			// Get all register link classes and convert NodeList to simple array.
			const registerLinks = [...navigation.querySelectorAll('p[class$="register-link--login"]')];
			navigation.querySelectorAll('.user-menu').forEach((userMenu) => {
				if (dhl.sso) {
					if (dhl.sso.isLoggedIn() === true) {
						userMenu.classList.add('logged-in');
						registerLinks.forEach(registerLink => {
							registerLink.classList.add('d-none');
						});
					} else {
						userMenu.classList.remove('logged-in');
						registerLinks.forEach(registerLink => {
							registerLink.classList.remove('d-none');
						});
					}
				} else {
					userMenu.classList.remove('logged-in');
				}
			});
		},

		/**
		 * Display or update the post number.
		 *
		 * @private
		 */
		_updatePostNumber() {
			const $postNumberElement = $('.user-menu .postnumber__number');
			if (dhl.sso) {
				$postNumberElement.text(dhl.sso.getPostNumber());
			} else {
				$postNumberElement.text(0);
			}
		},

		/**
		 * Shows the username for the different login states (known / not known / not logged in).
		 *
		 * @private
		 */
		_displayUsername() {
			const userNameComp = $('.user-menu__username');
			if (userNameComp !== undefined && userNameComp.length > 0) {
				if (dhl.sso && dhl.sso.isLoggedIn() === true) {
					userNameComp.text(dhl.sso.getUserName());
				} else {
					userNameComp.text(this.options.defaultText);
				}
			}
		},

		/**
		 * Open login layer automatically after page is loaded if user not logged in and auto login configuration true.
		 *
		 * @private
		 */
		_autoLogin() {
			if (this.options.autoLoginEnabled === 'true' && dhl.sso && !dhl.sso.isLoggedIn()) {
				const headerFragment = (this.options.autoLoginHeaderFragment !== '')
					? this.options.autoLoginHeaderFragment
					: this.options.headerFragment;
				const footerFragment = (this.options.autoLoginFooterFragment !== '')
					? this.options.autoLoginFooterFragment
					: this.options.footerFragment;
				const authenticationLevel = (this.options.authenticationLevel !== '')
					? parseInt(this.options.authenticationLevel, 10)
					: 3;
				dhl.sso.login(authenticationLevel, headerFragment, footerFragment, null, null);
			}
		},

		/**
		 * Redirect to configured page if user is not logged in and auto redirect is configured.
		 *
		 * @private
		 */
		_autoRedirect() {
			if (this.options.autoRedirectEnabled === 'true' && this.options.autoRedirectTargetPage !== '' && dhl.sso) {
				// Due to asynchronous JS execution we need to work with timeouts and check it every second for up to three seconds.
				const autoRedirectTimeout = window.setTimeout(() => {
					if (!dhl.sso.isLoggedIn()) {
						document.location.href = this.options.autoRedirectTargetPage;
					}
				}, 3000);
				let maxIntervalCount = 3;
				const checkLoginStatusInterval = window.setInterval(() => {
					if (dhl.sso.isLoggedIn()) {
						window.clearTimeout(autoRedirectTimeout);
					}
					maxIntervalCount -= 1;
					if (maxIntervalCount <= 0) {
						window.clearInterval(checkLoginStatusInterval);
					}
				}, 1000);
			}
		},

		/**
		 * Init on click listener for login & logout button.
		 *
		 * @private
		 */
		_registerLoginLogout() {
			navigation.querySelectorAll('.btn--logout').forEach((logoutBtn) => {
				const logoutText = logoutBtn.dataset.headerLogout;
				const logoutSuccessLinkTarget = logoutBtn.getAttribute(this.dataCiamLinkTarget);
				logoutBtn.addEventListener('click', () => {
					if (dhl.sso && dhl.sso.isLoggedIn()) {
						dhl.sso.logout(logoutText, logoutSuccessLinkTarget);
					}
				});
				logoutBtn.removeAttribute(this.dataCiamLinkTarget);
			});

			navigation.querySelectorAll('.btn--login').forEach((loginBtn) => {
				const loginSuccessLinkTarget = loginBtn.getAttribute(this.dataCiamLinkTarget);
				loginBtn.addEventListener('click', () => {
					if (dhl.sso && !dhl.sso.isLoggedIn()) {
						dhl.sso.login(3, this.options.headerFragment, this.options.footerFragment, loginSuccessLinkTarget, null);
					}
				});
				loginBtn.removeAttribute(this.dataCiamLinkTarget);
			});
		},

		/**
		 * Init on click listeners for user menu links. Direct to an alternative page depending on the login status. If user is not yet
		 * logged in, trigger login dialog. If login is successful direct to alternative page, else direct to page from href.
		 *
		 * @private
		 */
		_addListenersForUserMenuLinks() {
			// find login menu entries
			navigation.querySelectorAll('.dropdown__item > a').forEach((entry) => {
					const loginSuccessLinkTarget = entry.getAttribute(this.dataCiamLinkTarget);
					const loginAbortedLinkTarget = entry.href;

					if (loginSuccessLinkTarget != null) {
						if (dhl.sso.isLoggedIn() === true) {
							// redirect to logged in page
							entry.href = loginSuccessLinkTarget;
						} else {
							// trigger login window
							entry.addEventListener('click', (event) => {
								event.preventDefault();
								dhl.sso.login(3, this.options.headerFragment, this.options.footerFragment, loginSuccessLinkTarget,
									loginAbortedLinkTarget);
							});
						}
						entry.removeAttribute(this.dataCiamLinkTarget);
					}
				}
			);
		}
	});
}(ui.$));
