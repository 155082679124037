/* global ui */

(function ($) {
	$.widget('ipnp.searchslot', {

		options: {},

		_create() {
			const modalOptions = {
				closeButton: false,
				closeOnOutsideClick: true,
				skin: 'dark',
				width: 'auto',
				elements: [
					{
						content: this.element[0]
					}
				]
			};

			const onOpen = function () {
				if (ui.lib.getBreakpoint() !== 'xs') {
					$('#glightbox-body .gslide').addClass('container');
				}
				ui.pub('tracking.trackLayer', { name: $('header .searchslot__link').attr('href'), state: 'open' });
				ui.pub('popup.opened');
			};

			$('header .searchslot__link').on('click', () => {
				// opens search modal
				$.ipnp.Modal(modalOptions).showHtmlModal('modal--searchslot', onOpen, modalOptions.closeOnOutsideClick);
				// sets focus on search inputfield after modal is shown
				setTimeout(() => {
					$('#glightbox-body input[type="search"]').focus();
					$('#glightbox-body input[type="search"]').click();
				}, 300);
			});
		}
	});
}(ui.$));
