/* global ui */

(function ($) {
	$.widget('ipnp.pega', {

		options: {
			// 'mara' is used in the 'Privatkunden' (private customers) context.
			maraNbamServiceCtrl: getNBAMServiceControl('V3'),
			// 'salaut' is used in the 'Geschäftskunden' (business customers) context.
			salautNbamServiceCtrl: getNBAMServiceControl('V3'),
			pegaPkCookieName: 'dhlida',
			pegaGkCookieName: 'dhlpega'
		},

		_create: function () {
			let _this = this;
			if (window.pegaHosts !== undefined) {
				this.options.maraNbamServiceCtrl.initialize(window.pegaHosts.pegaPkHost, this.options.maraNbamServiceCtrl.port);
				this.options.salautNbamServiceCtrl.initialize(window.pegaHosts.pegaGkHost, this.options.salautNbamServiceCtrl.port);

				// Provide a PEGA object as window object for global access.
				if (window.pega === undefined) {
					window.pega = {
						/**
						 * Retrieve the PEGA service control for the 'Privatkunden' context.
						 *
						 * @returns The PEGA service control for the 'Privatkunden' context.
						 */
						get maraNbamServiceCtrl() {
							return _this.options.maraNbamServiceCtrl;
						},

						/**
						 * Retrieve the PEGA service control for the 'Geschäftskunden' context.
						 *
						 * @returns The PEGA service control for the 'Geschäftskunden' context.
						 */
						get salautNbamServiceCtrl() {
							return _this.options.salautNbamServiceCtrl;
						},

						/**
						 * Retrieve the name of the PEGA-PK cookie.
						 *
						 * @returns {string} The name of the PEGA-PK cookie
						 */
						get pegaPkCookieName() {
							return _this.options.pegaPkCookieName;
						},

						/**
						 * Retrieve the name of the PEGA-GK cookie.
						 *
						 * Hint: The cookie value is used as 'Customer ID' in the GK environment.
						 *
						 * @returns {string} The name of the PEGA-GK cookie
						 */
						get pegaGkCookieName() {
							return _this.options.pegaGkCookieName;
						},

						/**
						 * Retrieve the PEGA context as parameter for the offer list call.
						 *
						 * @returns {string} The predefined String 'Customer'.
						 */
						get pegaContext() {
							return 'Customer';
						},

						/**
						 * Retrieve the PEGA channel as parameter for the offer list call.
						 *
						 * @returns {string} The predefined String 'Web'.
						 */
						get pegaChannel() {
							return 'Web';
						},

						/**
						 * Retrieve the current page.
						 *
						 * @returns {string} The complete URL of the current page
						 */
						get pegaCurrentPage() {
							return window.location.href;
						},

						/**
						 * Retrieve the previous page. This call works with the 'Referrer' which might not be always set.
						 *
						 * @returns {string} The previous page of the 'referrer' attribute is set by the browser.
						 */
						get pegaPreviousPage() {
							return document.referrer;
						},

						/**
						 * Request the offer from PEGA. Function is 'async' in order to wait for fetching the JWT token for PK and to wait
						 * for the response from the PEGA backend.
						 *
						 * @param pegaEnvironment The PEGA environment (either 'mara' == PK or 'salaut' == GK).
						 * @param pegaContainerId The configured ID of the component; PEGA uses this information for matching the correct
						 *     component.
						 * @returns {Promise<null>} Promise returns 'undefined', therefore, it can be ignored using 'void'.
						 */
						async getPegaOffer(pegaEnvironment, pegaContainerId) {
							const pegaServiceCtrl = pegaEnvironment === 'mara' ? pega.maraNbamServiceCtrl : pega.salautNbamServiceCtrl;
							const pegaServiceClass = pegaServiceCtrl.getServiceClass();
							let pegaOffer = null;
							let customerId = null;
							let externalId = null;
							// Check PEGA environment ('mara' == PK, 'salaut' == GK).
							if (pegaEnvironment === 'salaut') {
								ui.lib.cookie.get({
									name: pega.pegaGkCookieName,
									callback: (pegaGkCookie) => {
										if (pegaGkCookie !== null) {
											customerId = pegaGkCookie;
										}
									}
								});
							} else if (pegaEnvironment === 'mara') {
								// Retrieve the JWT token as 'customerId'.
								if (dhl && dhl.sso && dhl.sso.isLoggedIn()) {
									customerId = await dhl.sso.getPkUiJwtToken();
								}
								// Retrieve the cookie for PEGA-PK as 'externalId'.
								ui.lib.cookie.get({
									name: pega.pegaPkCookieName,
									callback: (pegaPkCookie) => {
										if (pegaPkCookie !== null) {
											externalId = pegaPkCookie;
										}
									}
								});
							} else {
								ui.log('ERROR', 'PEGA environment is neither "mara" nor "salaut".');
								return null;
							}
							// Call PEGA and wait for the response for further processing.
							pegaOffer = await new Promise(resolve => {
								pegaServiceCtrl.getOffers(customerId, pega.pegaContext, pegaContainerId, externalId, pega.pegaChannel,
									pega.pegaPreviousPage, pega.pegaCurrentPage, (pegaResponse) => {
										resolve(_this._getPegaOfferFromResponse(pegaServiceClass, pegaResponse));
									});
							});

							return pegaOffer;
						}
					};
				}
			}
		},

		/**
		 * Retrieve the correct response object from PEGA. The objects differ from the previous version and the version "V3".
		 *
		 * @param pegaServiceClass The service class ("version") for differentiation of the response handling.
		 * @param pegaResponse The received response object from PEGA.
		 *
		 * @returns {object|null|undefined} The PEGA response object according to the defined version / service class.
		 */
		_getPegaOfferFromResponse(pegaServiceClass, pegaResponse) {
			let pegaResult = null;
			if (pegaResponse !== undefined) {
				// Check for PEGA "version" because the responses differ.
				if (pegaServiceClass !== undefined && pegaServiceClass.toUpperCase() === 'V3') {
					if (pegaResponse.ContainerList && pegaResponse.ContainerList.length > 0) {
						pegaResult = pegaResponse.ContainerList[0].RankedResults?.[0];
					}
				} else {
					if (pegaResponse.OffersList && pegaResponse.OffersList.length > 0) {
						pegaResult = pegaResponse.OffersList[0];
					}
				}
			}
			return pegaResult;
		}
	});
}(ui.$));
