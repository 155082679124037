/* global ui */

/**
 * A widget for global login functionality with CIAM.
 */

(function ($) {
	$.widget('ipnp.ssoLogin', {

		/**
		 * Creates the widget for starting CIAM if configured.
		 */
		_create() {
			const widgetId = 'ssoCiam';
			const { ciamConfigured } = $(document.body).data();
			let counter = 0;

			if (ciamConfigured) {
				const ciamDetectionInterval = window.setInterval(() => {
					if (!!ciamConfigured && window.ciam) {
						window.clearInterval(ciamDetectionInterval);
						ui.register({
							widget: widgetId
						});

						if (typeof ui.widgets[widgetId] === 'undefined') {
							ui.log(`Failed to initialize CIAM. Widget with the given ID not found: ${widgetId}`);
						} else {
							ui.log(`Successfully initialized CIAM. Widget with the given ID now running: ${widgetId}`);
							ui.widgets[widgetId].init();
						}
					}
					if (counter > 1500) {
						// wait 1.5s for an CIAM Object to show up
						window.clearInterval(ciamDetectionInterval);
					}
					counter += 1;
				});
			}
		}
	});
}(ui.$));
