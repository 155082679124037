/* global ui, dhl */

(function ($) {
	$.widget('ipnp.bonus-points', {

		options: {
			cookieName: 'dhlbuip',
			expiryTime: 0.04,
			bonusUIApiPath: '',
			bonusPointsText: ''
		},

		_create() {
			const _this = this;

			// if we don't have output, we won't run
			if (this.element.length === 0) {
				return;
			}
			this.options.bonusPointsText = this.element.data('bonus-text');
			this.options.bonusUIApiPath = this.element.data('bonus-api-path');
			ui.sub('login.changed', () => {
				// Read cookie information if user logs in and either load bonus points information
				// from the 'Bonus UI' API or from the cookie. Only if authentication level is equal or higher than 2.
				if (dhl.sso.getAuthenticationLevel() >= 2) {
					ui.lib.cookie.get({
						name: _this.options.cookieName,
						callback(cookieValue) {
							_this._process(cookieValue);
						}
					});
				} else if (dhl.sso.getAuthenticationLevel() <= 1) {
					// Delete the created cookie if the authentication level is equal or lower than 1.
					ui.lib.cookie.delete({
						name: _this.options.cookieName
					});
					_this.element.hide();
				}
			});

			ui.sub('header.bonus.refresh', () => {
				_this.loadBonusPoints();
			});
		},

		/**
		 * Reads out and process the cookie information.
		 *
		 * @param {Object} cookieValue - value of the cookie.
		 */
		_process(cookieValue) {
			const _this = this;
			if (cookieValue === null) {
				// Load bonus points information from the 'Bonus UI' API.
				_this.loadBonusPoints();
			} else {
				// Read out information from the cookie if one exists.
				_this.setCachedBonusPoints(cookieValue);
			}
		},

		/**
		 * Load bonus points information from the 'Bonus UI' API via AJAX. Information will only be displayed
		 * if request was successful (HTTP status code = 200).
		 */
		loadBonusPoints() {
			const _this = this;
			if (_this.options.bonusUIApiPath) {
				$.ajax({
					url: _this.options.bonusUIApiPath,
					success(data, textStatus) {
						ui.log(`Display of bonus points, HTTP status code: ${textStatus}`);
						_this.setReceivedBonusPoints(data);
					},
					error(jqXHR, textStatus) {
						ui.log(`No display of bonus points, invalid HTTP status code: ${textStatus}`);
						_this.element.hide();
					}
				});
			}
		},

		/**
		 * Sets the received bonus points information from the 'Bonus UI' API.
		 *
		 * @param {Object} jsonData - received information from the 'Bonus UI' API.
		 */
		setReceivedBonusPoints(jsonData) {
			const bonusPoints = jsonData.points;
			this.updatePointsDisplay(bonusPoints);
			// Set a cookie which saves the current userID and the current bonus points up to 60 minutes.
			ui.pub('cookie.set', {
				name: this.options.cookieName,
				value: [dhl.sso.getUserID(), bonusPoints],
				days: this.options.expiryTime
			});
		},

		/**
		 * Sets the cached bonus points information from the created cookie.
		 *
		 * @param {Object} cookieData - cached information from the created cookie.
		 */
		setCachedBonusPoints(cookieData) {
			const cachedData = cookieData.split(',');
			// Check if cached userID equals the current userID.
			if (dhl.sso.getUserID() === cachedData[0]) {
				this.updatePointsDisplay(parseInt(cachedData[1], 10));
			} else {
				// Load new bonus points information because another userID is used.
				// (We assume a new user has logged in via the same device/browser.)
				this.loadBonusPoints();
			}
		},
		/**
		 * updates the displayed points and shows element
		 */
		updatePointsDisplay(points) {
			if (points !== undefined) {
				this.element.each((index, element) => {
					$(element).find('.bonus-points__value')
						.text(`${points.toLocaleString('de-DE')} ${this.options.bonusPointsText}`);
				});
				this.element.show();
			} else {
				ui.log('Could not update bonus points value.');
			}
		}
	});
}(ui.$));
