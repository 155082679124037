/*
 * This is the main javascript file of all client DHL specific scripts.
 * Component specific scripts where included by the dhl.bundle.js file of the client specific magnolia module.
 */

/* eslint-disable */

//Tracking
import './ui.legacy/ui.tracking';

// PWZ
import '../../../webresources/static/clientlibs-pwz';

// PEGA
import '../../fragments/pega/realtimecontainerscript';
import '../../fragments/pega/pega';
import '../../fragments/pega/pega-pk-cookie';
import '../../fragments/pega/pega-gk-cookie';

// DHL Components
import '../../components/header/primary-navigation';
import '../../components/header/navigation.mobile';
import '../../components/header/header.searchslot';
import '../../components/stage/stage';
import '../../components/stage/quickaccess';
import '../../components/step-by-step/step-by-step';
import '../../components/teaser/teaser';
import '../../fragments/iframe/iframe';
import '../../fragments/login/ciam/sso.ciam';
import '../../fragments/login/ciam/login.navigation';
import '../../fragments/login/login.sso';
import '../../fragments/login/bonus-points/bonus.points';
import '../../fragments/flyin/flyin';
import '../../fragments/dark-switch/dark-switch';
import '../../../../base/templates/components/circle-icon-expandable/circle-icon-expandable';
import '../../../../base/templates/components/cia/cia';

// initialize jquery widgets
import './ui.legacy/ui.index';
