// This function specifies only primary navigation on desktop!

/* global ui */
(function ($) {
	$.widget('ipnp.primary-navigation', {

		options: {},

		_create() {
			this._triggerLinkOnEnter($('.secondary-navigation__tab'));
			this._alignDropdowns();
			this._initPrimaryNavigation();
			this._toggleLanguageMenu();
			this._toggleUserMenu();
			this._initLinkTracking();
		},

		/*  Add / Remove 'js-focus-class' when focusing a menu link
		 *  -> class will be added when focusing menu link
		 *  -> class will be removed when blur dropdown or it's content
		 */
		_initPrimaryNavigation() {
			const $primaryNavTab = $('.primary-navigation__tab');

			$primaryNavTab.focus(() => {
				$primaryNavTab.removeClass('js-focus');
			}).keydown(function (e) {
				const code = e.keyCode || e.which;
				if (code === $.ui.keyCode.ENTER) {
					if (!$(this).hasClass('js-focus') && $(this).hasClass('has-dropdown')) {
						e.preventDefault();
					} else {
						$(this).children('a:first')[0].click();
					}
					$(this).addClass('js-focus');
				}
			}).mouseenter(function () {
				$(this).addClass('js-focus');
			}).mouseleave(function () {
				$(this).removeClass('js-focus');
			}).blur(function () {
				const $primaryNavDropdown = $(this).find('.primary-navigation__dropdown');

				setTimeout(() => {
					if ($primaryNavDropdown.find(':focus').length === 0) {
						$(this).removeClass('js-focus');
					}
				}, 1);
			});

			const $primaryNavDropdownContent = $('.primary-navigation__dropdown *');

			$primaryNavDropdownContent.on('blur mouseenter', function (event) {
				const $primaryNavDropdown = $(this).closest('.primary-navigation__dropdown');
				const $linkPrimary = $primaryNavDropdown.parent().find('.link-primary');

				if (event.type === 'blur') {
					setTimeout(() => {
						if ($primaryNavDropdown.find(':focus').length === 0) {
							$linkPrimary.removeClass('js-focus');
						}
					}, 1);
				} else {
					$linkPrimary.removeClass('js-focus');
				}
			});
		},

		_toggleLanguageMenu() {
			const $languageMenu = $('.primary-navigation__wrapper ul.primary-navigation__tabs > li.lang');

			$languageMenu.on('focus mouseenter', function (event) {
				if (event.type === 'mouseenter') {
					$(this).blur();
				}
				$(this).addClass('js-focus');
			}).blur(function () {
				const $languageMenuDropdown = $(this).find('div.lang__list');
				setTimeout(() => {
					if ($languageMenuDropdown.find(':focus').length === 0) {
						$(this).removeClass('js-focus');
					}
				}, 1);

				$languageMenuDropdown.blur(function () {
					if ($(this).find(':focus').length === 0) {
						$(this).removeClass('js-focus');
					}
				});
			});

			const $languageMenuContent = $('.primary-navigation__wrapper ul.primary-navigation__tabs > li.lang > .lang__list *');
			$languageMenuContent.blur(function () {
				const $languageMenuDropdown = $(this).closest('div.lang__list');
				setTimeout(() => {
					if ($languageMenuDropdown.find(':focus').length === 0) {
						$languageMenuDropdown.closest('li.lang').removeClass('js-focus');
					}
				}, 1);
			}).mouseenter(function () {
				const $languageMenuDropdown = $(this).closest('div.lang__list');
				$languageMenuDropdown.closest('li.lang').removeClass('js-focus');
			}).keyup(function (e) {
				const code = e.keyCode || e.which;
				if (code === $.ui.keyCode.ENTER) {
					const a = $(this).find('a');
					if (a.length > 0) {
						a[0].click();
					}
				}
			});

			// keep query on language switch
			const languageSwitchElements = this.element[0].querySelectorAll('li.lang > .lang__list a.lang__option');
			languageSwitchElements.forEach(
				languageSwitchElement => languageSwitchElement.addEventListener('click', () => {
					if (window.location.search) {
						const linkTarget = languageSwitchElement.getAttribute('href');
						if (linkTarget.indexOf('?') === -1) {
							languageSwitchElement.setAttribute('href', linkTarget + window.location.search);
						}
					}
				})
			);
		},

		_toggleUserMenu() {
			const $userMenuItem = $('.primary-navigation__wrapper ul.primary-navigation__tabs > li.user-menu li.dropdown__item');
			const $userMenu = $('.primary-navigation__wrapper ul.primary-navigation__tabs > li.user-menu');

			this._triggerLinkOnEnter($userMenuItem);

			$userMenu.focus(() => {
				$('.primary-navigation__tab.has-dropdown').removeClass('js-focus');
				$userMenu.removeClass('js-focus');
			}).keydown(function (e) {
				const code = e.keyCode || e.which;
				if (code === $.ui.keyCode.ENTER) {
					if (!$(this).hasClass('js-focus') && $(this).hasClass('has-dropdown')) {
						e.preventDefault();
					} else {
						$(this).children('a:first')[0].click();
					}
					$(this).addClass('js-focus');
				}
			}).mouseenter(function () {
				$(this).addClass('js-focus');
			}).mouseleave(function () {
				$(this).removeClass('js-focus');
			}).blur(function () {
				const _this = $(this);
				const $primaryNavDropdown = $(this).find('ul.primary-navigation__dropdown');
				setTimeout(() => {
					if ($primaryNavDropdown.find(':focus').length === 0) {
						_this.removeClass('js-focus');
					}
				}, 1);
			});

			const $userMenuContent = $('ul.primary-navigation__tabs > li.user-menu > .primary-navigation__dropdown *');
			$userMenuContent.blur(function () {
				const $primaryNavDropdown = $(this).closest('ul.primary-navigation__dropdown');
				setTimeout(() => {
					if ($primaryNavDropdown.find(':focus').length === 0) {
						$primaryNavDropdown.closest('li.user-menu').removeClass('js-focus');
					}
				}, 1);
			}).mouseenter(function () {
				const $primaryNavDropdown = $(this).closest('ul.primary-navigation__dropdown');
				$primaryNavDropdown.closest('li.user-menu').removeClass('js-focus');
			});
		},

		_alignDropdowns() {
			$('.primary-navigation__wrapper .has-dropdown').hover(function () {
				const $primaryNavDropdown = $(this).find('.primary-navigation__dropdown').not('.sub-menu');
				if ($primaryNavDropdown.length) {
					const dropdownPositionRight = $primaryNavDropdown.offset().left + $primaryNavDropdown.outerWidth();
					const $header = $('.header');
					const headerPositionRight = $header.offset().left + $header.outerWidth();

					if (dropdownPositionRight > headerPositionRight) {
						$primaryNavDropdown.addClass('align-right');
					}
				}
			});
		},

		_initLinkTracking() {
			this.element.find('a').on('click', () => {
				ui.pub('tracking.updateEvents', { navigationUsed: 'true' });
			});
		},

		_triggerLinkOnEnter($element) {
			$element.keydown(function (e) {
				const code = e.keyCode || e.which;
				if (code === $.ui.keyCode.ENTER) {
					$(this).children('a:first')[0].click();
				}
			});
		}
	});
}(ui.$));
