/* global ui */

(function ($) {
	// Regex for replacing the PEGA image inside the 'srcset' definition.
	const srcSetRegex = /\/.*(?=\?)/gm;

	$.widget('ipnp.teaser', {

		options: {
			defaultTeaserHtml: null
		},

		_create() {
			// Check and handle configured PEGA teasers only on public instances.
			if (!ui.lib.isAuthorInstance()) {
				const teaser = this.element.get(0);
				// Store the initial state of the teaser for use cases where PEGA has no available offer (e.g. after logging out).
				this.options.defaultTeaserHtml = teaser.innerHTML;
				void this._handlePegaResponse(teaser);
				ui.sub('login.changed', () => {
					void this._handlePegaResponse(teaser);
				});
			}
		},

		/**
		 * Trigger and handle the exchange of the content for the corresponding teaser via PEGA. Function is 'async' in order to wait for
		 * fetching the JWT token for PK.
		 *
		 * @param teaser The default teaser for exchanging the content.
		 * @returns {Promise<void>} Promise returns 'undefined', therefore, it can be ignored using 'void'.
		 * @private
		 */
		async _handlePegaResponse(teaser) {
			const usePega = teaser.dataset.usePega;
			const pegaEnvironment = teaser.dataset.pegaEnvironment;
			const pegaContainerId = teaser.dataset.pegaContainerId;
			if (usePega === 'true' && pegaEnvironment && pegaContainerId && pega) {
				const pegaOffer = await pega.getPegaOffer(pegaEnvironment, pegaContainerId);
				this._handlePegaOffer(pegaOffer, teaser);
			}
		},

		/**
		 * Handle the offer from PEGA to exchange content of the teaser.
		 *
		 * @param pegaOffer The offer received from calling PEGA.
		 * @param teaser The default teaser for exchanging the content.
		 * @private
		 */
		_handlePegaOffer(pegaOffer, teaser) {
			if (pegaOffer !== undefined && pegaOffer !== null) {
				// Exchange the image.
				if (pegaOffer.ImageURL && teaser.querySelector('.teaser__image') !== null) {
					const teaserImages = teaser.querySelector('.teaser__image picture').children;
					for (let teaserImage of teaserImages) {
						switch (teaserImage.nodeName) {
							case 'IMG':
								teaserImage.src = pegaOffer.ImageURL;
								break;
							case 'SOURCE':
								teaserImage.srcset = teaserImage.srcset.replaceAll(srcSetRegex, pegaOffer.ImageURL);
								break;
							default:
								ui.log('WARN', 'Not handled DOM element in picture area.');
						}
					}
				}
				// Exchange the title.
				if (pegaOffer.ShortDescription) {
					const teaserTitle = teaser.querySelector('.teaser__title');
					teaserTitle.innerText = pegaOffer.ShortDescription;
				}
				// Exchange the text.
				if (pegaOffer.Benefits) {
					const teaserText = teaser.querySelector('.teaser__text .rtf--custom');
					teaserText.innerText = pegaOffer.Benefits;
				}
				// Exchange the link.
				if (pegaOffer.ClickThroughURL) {
					const teaserLink = teaser.querySelector('.teaser__link');
					teaserLink.href = pegaOffer.ClickThroughURL;
				}
			} else {
				// Restore default teaser, if its content has already been replaced by PEGA; e.g. after logging out.
				teaser.innerHTML = this.options.defaultTeaserHtml;
			}
		}
	});
}(ui.$));
