/* global ui */

(function ($) {
	$.widget('ipnp.quickaccess', {

		options: {
			itemClass: '.quickaccess-item'
		},

		_create() {
			const _this = this;

			if (ui.lib.getBreakpoint() === 'xs') {
				_this._initMobile();
			} else {
				_this._toggleActiveItem();
				_this._addIconAnimation();
			}

			ui.sub('ui.breakpointChanged', (e, data) => {
				if (data === 'xs') {
					_this._initMobile();
				} else {
					_this._hidePopup();
					$('.quickaccess-item--open-modal', this.element).off();
					_this._toggleActiveItem();
					_this._addIconAnimation();
				}
			});
		},

		/**
		 * Sets selected item to active and shows its content.
		 *
		 * @private
		 */
		_toggleActiveItem() {
			const _this = this;
			const $quickaccessItem = this.element.find(this.options.itemClass);
			this.element.find('.quickaccess-item--open-modal').on('click keydown', function (event) {
				if (event.which === 13 || event.type === 'click') {
					// No content animation for initial opened quickaccess items.
					if (!$(this).hasClass('quickaccess-item--initial')) {
						_this.element.find('.quickaccess-item--active').find('.quickaccess-item__content')
							.css('animation', 'zoom-out 300ms linear');
						$quickaccessItem.removeClass('quickaccess-item--active');
						$(this).addClass('quickaccess-item--active');
						$(this).find('.quickaccess-item__content').css('animation', 'zoom-in 300ms linear');
						$quickaccessItem.removeClass('quickaccess-item--initial');
					}
				}
			});
		},

		/**
		 * Handle events for mobile popup.
		 *
		 * @private
		 */
		_initMobile() {
			const _this = this;
			// Handle opening of popup.
			this._on($('.quickaccess-item--open-modal', this.element), {
				click: (event) => {
					_this._openPopup(event.currentTarget);
				}
			});
		},

		/**
		 * Handle mobile popup.
		 *
		 * @param target Current target of the event.
		 * @private
		 */
		_openPopup(target) {
			const modalContent = $(target).find('.quickaccess-item__content')[0].outerHTML;
			const modalOptions = {
				closeButton: true,
				closeOnOutsideClick: true,
				elements: [
					{
						content: modalContent
					}
				]
			};

			const onOpen = function () {
				document.getElementById('glightbox-body').getElementsByClassName('ginlined-content')[0].classList.add('quickaccess__popup');
			};

			$.ipnp.Modal(modalOptions).showHtmlModal('modal--quickaccess', onOpen, modalOptions.closeOnOutsideClick);

			// sets focus on search inputfield after modal is shown
			setTimeout(() => {
				$('#glightbox-body .quickaccess-item__searchfield-input').focus();
				$('#glightbox-body .quickaccess-item__searchfield-input').click();
			}, 300);
		},

		/**
		 * Hide / Close mobile popup.
		 *
		 * @private
		 */
		_hidePopup() {
			ui.pub('ui.modal.close');
			ui.pub('ui.modal.destroy');
			this.element.unbind('click');
		},

		/**
		 * Add hover icon animation effects for quickaccess items.
		 * Hint: The animations are strictly tied to the SVGs, therefore any update of the SVGs might result in an update of this function.
		 *
		 * @private
		 */
		_addIconAnimation() {
			const _this = this;
			// Hover animation effect for icon "Online versenden".
			$('.quickaccess-item[data-icon="icon_onlineversenden.svg"]').hover(function () {
				const svgDoc = $(this).find('object')[0].contentDocument;
				_this._addSvgAnimation(svgDoc, 'bpczdk39g30rk16ero0l9632', 'bpczdk39g30rk16ero0l9632_t');
				_this._addSvgAnimation(svgDoc, 'xice5tqqqboobmtd1mvbht72', 'xice5tqqqboobmtd1mvbht72_t');
				_this._addSvgAnimation(svgDoc, 'v0fx9qkee2qfq8ac1v2wly2q', 'v0fx9qkee2qfq8ac1v2wly2q_t');
			}, function () {
				const svgDoc = $(this).find('object')[0].contentDocument;
				const svgElementIdArray = ['bpczdk39g30rk16ero0l9632', 'xice5tqqqboobmtd1mvbht72', 'v0fx9qkee2qfq8ac1v2wly2q'];
				_this._removeSvgAnimation(svgDoc, svgElementIdArray);
			});
			// Hover animation effect for icon "Paketsuche".
			$('.quickaccess-item[data-icon="icon_paketsuche.svg"]').hover(function () {
				const svgDoc = $(this).find('object')[0].contentDocument;
				_this._addSvgAnimation(svgDoc, 'a0', 'a0_t');
				_this._addSvgAnimation(svgDoc, 'weiss', 'a1_t');
				_this._addSvgAnimation(svgDoc, 'weiss-path', 'weiss_t');
				_this._addSvgAnimation(svgDoc, 'lupe', 'lupe_t');
			}, function () {
				const svgDoc = $(this).find('object')[0].contentDocument;
				const svgElementIdArray = ['a0', 'weiss', 'weiss-path', 'lupe'];
				_this._removeSvgAnimation(svgDoc, svgElementIdArray);
			});
			// Hover animation effect for icon "Standort".
			$('.quickaccess-item[data-icon="icon_standort.svg"]').hover(function () {
				const svgDoc = $(this).find('object')[0].contentDocument;
				_this._addSvgAnimation(svgDoc, 'red-elipse', 'red-elipse_t');
				_this._addSvgAnimation(svgDoc, 'white-elipse', 'white-elipse_t');
				_this._addSvgAnimation(svgDoc, 'white-', 'a0_t');
				_this._addSvgAnimation(svgDoc, 'white-path', 'white-_t');
				_this._addSvgAnimation(svgDoc, 'pin', 'a1_t');
				_this._addSvgAnimation(svgDoc, 'pin-path', 'pin_t');
			}, function () {
				const svgDoc = $(this).find('object')[0].contentDocument;
				const svgElementIdArray = ['red-elipse', 'white-elipse', 'white-', 'white-path', 'pin', 'pin-path'];
				_this._removeSvgAnimation(svgDoc, svgElementIdArray);
			});
		},

		/**
		 * Add animation of SVG for hover effect. The css keyframe animation is inside the SVG icon file.
		 *
		 * @param svgDoc SVG document of animated svg icon.
		 * @param svgElementId ID of animated SVG path or rectangle.
		 * @param cssAnimation Keyframe name CSS animation in SVG icon.
		 * @private
		 */
		_addSvgAnimation(svgDoc, svgElementId, cssAnimation) {
			svgDoc.getElementById(svgElementId).style.animation = `1.5s linear 1 both ${cssAnimation}`;
		},

		/**
		 * Removes animation of SVG
		 *
		 * @param svgDoc SVG document of animated SVG icon.
		 * @param svgElementIdArray Array with IDs of animated SCG paths or rectangles.
		 * @private
		 */
		_removeSvgAnimation(svgDoc, svgElementIdArray) {
			svgElementIdArray.forEach((svgElementId) => {
				svgDoc.getElementById(svgElementId).style.animation = '';
			});
		}
	});
}(ui.$));
