/* global ui */

(function ($) {
	$.widget('ipnp.pega-gk-cookie', {
		_create() {
			const cookieParamE = ui.getURLParameter('e');
			const cookieParamU = ui.getURLParameter('u');
			if (cookieParamE != null && cookieParamU != null) {
				ui.lib.cookie.set({
					name: pega.pegaGkCookieName,
					value: [cookieParamE + ';' + cookieParamU],
					days: 24855
				});
			}
		}
	});

}(ui.$));
